import React from "react";

import PageWrapper from "../components/PageWrapper";

const FormSub = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "pt-13 pt-lg-11",
          headerLogoClassName: "mx-auto text-center",
        }}
      >
        <div className="min-height-100vh d-flex align-items-center pt-15 pb-13 pt-lg-32 pb-lg-27 bg-default-3">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-7 col-md-9 col-sm-11">
                <div className="pt-9 pt-sm-13 pb-10 px-5 px-xs-14">
                  <div
                    className="mb-10 text-center"
                    data-aos="fade-up"
                    data-aos-duration={500}
                    data-aos-delay={500}
                  >
                    <h2 className="mb-1 font-size-10 letter-spacing-n83">
                      Obrigado por se cadastrar!
                    </h2>
                    <p className="text-bali-gray font-size-7 mb-0">
                      Você será notificado sobre o lançamento em breve.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default FormSub;
